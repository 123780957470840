import React, { useState, useEffect } from "react";
import { View,Platform, SafeAreaView,StatusBar,Image as Photo,Text} from "react-native";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector, useDispatch } from "react-redux";
import { getPool } from "../components/aws-exports";
import styles from "../components/style/styles";
import { getProductDetails, getOrganisationById, myDetails } from "../actions";
import {
  setProduct,
  setCustomer,
} from "../components/redux/actions/prodActions";
import { setUserPoolData } from "../components/redux/actions/prodActions";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import { setAuthHeader } from "../axiosApi";
import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";
import CoreLoader from "../components/core/CoreLoader";
import { Status, ReadyToSaleStatus } from "../sharedLib/sourcingItemStatus.js";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import Button from "../components/Button";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PrintBarcode from "./barcode";
import { Checkbox } from "react-native-paper";
import Colors from "../constants/Colors";
import Barcode from "react-barcode";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");
const imageSrc = require("../assets/images/logo.png");

let _user;
let orgName;
const SoldClothBagCollection = ({ navigation, route }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pickOrg = route.params.organisationId;
  const [requriedAllItems, setRequriedAllItems] = useState([]);
  const [forSaleItems, setForSaleItems] = useState([]);
  const [soldItems, setSoldItems] = useState([]);
  const [itemExpired, setItemExpired] = useState([]);
  const [loadItem, setLoadItem] = useState(true);
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const selfServiceFlow = orgDetail?.[0]?.selfServiceFlow;

  const [customerDetail, setCustomerDetail] = useState({});
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const [activeFilter, setActiveFilter] = useState("all");
  const [barcodeSettingObject, setBarcodeSettingObject] = useState({});
  const [allBarcodeData, setAllBarcodeData] = useState([]);

  useEffect(() => {
    if ((/^[\],:{}\s]*$/.test(orgSetting?.barcode_setting?.replace(/\\["\\\/bfnrtu]/g, '@').
      replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
      replace(/(?:^|:|,)(?:\s*\[)+/g, '')))) {
      setBarcodeSettingObject(JSON.parse(orgSetting?.barcode_setting) || {});
    } else {
      setBarcodeSettingObject({
        barcode_setting: orgSetting?.barcode_setting,
      });
    }
  }, [orgSetting]);

  useEffect(async () => {
    let poolData = await getPool();
    dispatch(setUserPoolData(poolData));
  }, []);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const handleGetItem = async () => {
    await myDetails(orgName)
      .then((resp) => {
        setCustomerDetail(resp?.data[0]);
      })
      .catch((error) => { });
    await getProductDetails(orgName)
      .then(({ data }) => {
        const tmpSold = [];
        const tmpForSale = [];
        const tmpAllItems = [];
        const tmpExpired = [];
        data?.forEach((element) => {
          // If the item is sold
          if (element.sale_status == ReadyToSaleStatus.Sold) {
            element.label =
              "Plagget är sålt, din komission är " +
              (element.commission * element.price) / 100 +
              " kr";
            tmpSold.push(element);
          }
          // The item is ready to be sold
          if (element.sale_status == ReadyToSaleStatus.IsReadyToBeSold) {
            element.label =
              "Plagget är ute till försäljning för " + element.price + " kr";
            tmpForSale.push(element);
          }
          // The item is expired
          if (element.sale_status == ReadyToSaleStatus.Expired) {
            tmpExpired.push(element);
          }
          // The admin is suppose to mark it as accepted
          if (element.status == Status.Pending) {
            element.label = "Butiken överväger plagget";
            tmpAllItems.push(element);
          }
          // Some action is requried, the user needs to send it to the merchent
          if (element.status == Status.Accepted) {
            element.label = "Plagget är godkänt och klart för försäljning";
            //  element.label = "Skicka plagget till butiken"
            element.type = "transport";
            tmpAllItems.push(element);
          }
          // Some action is requried, the user has an counter offer to either accept or reject
          if (element.status == Status.CounterOffer) {
            element.label = "Förslag ifrån butiken";
            element.type = "counteroffer";
            tmpAllItems.push(element);
          }
        });

        setSoldItems(tmpSold);
        setForSaleItems(tmpForSale);
        setRequriedAllItems(tmpAllItems);
        setItemExpired(tmpExpired);

        setLoadItem(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    navigation.addListener("focus", async () => {
      setLoadItem(true);
      if (orgName) {
        handleGetItem();
      }
    });
    return () => {
      console.log("======nativeUnmount");
    };
  }, [navigation]);

  useEffect(async () => {
    orgName = await AsyncStorage.getItem("organisation");
    setLoadItem(true);
    const _token = await AsyncStorage.getItem("token");
    setAuthHeader(_token);
    const getUser = await AsyncStorage.getItem("customer");
    _user = JSON.parse(getUser);
    dispatch(setCustomer(_user));
    await getOrganisationById(pickOrg)
      .then((resp) => {
        dispatch(setProduct(resp?.data));
        setIsLoad(false);
      })
      .catch((error) => {});
    handleGetItem();
  }, []);

  const totalComission = soldItems
    ?.filter((item) => item.sale_status === ReadyToSaleStatus.Sold)
    ?.reduce((sum, item) => item.sale_status && sum + item.commission, 0);

  const handleClick = async (sourceItem) => {
    PrintBarcode(sourceItem, barcodeSettingObject, orgSetting);
  };

  function handleActionRequired(item) {
    if (item.status === Status.CounterOffer) {
      navigation.navigate("SoldClothBagCounterOffer", {
        item: item.id,
        totalItem: soldItems?.length,
        organisationId: pickOrg,
        allComission: totalComission,
      });
    }

    if (item.status === Status.Accepted) {
      navigation.navigate("DropOffItem", {
        item: item.id,
        totalItem: soldItems?.length,
        organisationId: pickOrg,
        allComission: totalComission,
      });
    }
  }
  const getFormattedExpirationDate = (createdat, expireDay) => {
    const creationDate = new Date(createdat);
    const expirationDate = new Date(creationDate);
    expirationDate.setDate(creationDate?.getDate() + expireDay);

    const formattedDate = expirationDate?.toISOString().split("T")[0];
    return formattedDate;
  };

  const ExpiredComponent = ({ createdat, expireDay }) => {
    const expirationDate = getFormattedExpirationDate(createdat, expireDay);
    return (
      <CoreText
        style={[styles.labelMargin, styles.storeText]}
        textTitle={`Utgånget datum: ${moment(expirationDate).format(
          "YYYY/MM/DD"
        )}`}
        fontType={MontserratBold}
      />
    );
  };
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentWeekNumber = moment().isoWeek();
  const barcodeData = barcodeSettingObject?.barcode_setting?.split(",");

  const handlePrint = () => {
    const _width = barcodeSettingObject?.barcodePage === "l" ? barcodeSettingObject?.height || 80 : barcodeSettingObject?.width || 100;
    const _height = barcodeSettingObject?.barcodePage === "l" ? barcodeSettingObject?.width || 100 : barcodeSettingObject?.height || 80;

    const doc = new jsPDF(barcodeSettingObject?.barcodePage || "l", "mm", [ _height, _width]);
    let lineHeight = barcodeSettingObject?.lineHeight || 2;
    let StartingPoint = barcodeSettingObject?.startingPoint || 5;

    doc.setFontSize(11);
    let lineHeightIncrement = (barcodeSettingObject?.fontSize || 10) / 2;
    lineHeightIncrement = lineHeightIncrement * 0.8;

    allBarcodeData.forEach((item, rowIndex) => {
      const brand = item?.brand && <Text>{item?.brand}</Text>
      const category = item?.category && <Text>{item?.category} </Text>
      const vat = item?.vat && <Text>{item?.vat}</Text>
      const description = item?.description && <Text>{item?.description} </Text>
      const month = <Text>{currentMonth}</Text>
      const week = <Text>{currentWeekNumber}</Text>

      const allFields = {
        Brand: brand,
        Category: category,
        Vat: vat,
        Description: description,
        Month: month,
        Week: week,
        None: "",
      };

      const svgContainer = document.createElement("div");
      const barcodeElement = <Barcode value={item?.sku} displayValue={false} />;
      ReactDOM.render(barcodeElement, svgContainer);
      const svg = svgContainer.querySelector("svg");

      if (item?.sku) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const svgData = new XMLSerializer().serializeToString(svg);
        const img = new Image();

        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const dataUrl = canvas.toDataURL("image/png");

          if (rowIndex > 0) {
            doc.addPage(barcodeSettingObject?.barcodePage || "l", "mm", [ _height, _width ]);
            lineHeight = barcodeSettingObject?.lineHeight || 2;
            doc.setFontSize(11);
          }

          doc.addImage(dataUrl, "PNG", StartingPoint - 3,  lineHeight, 60, barcodeSettingObject?.barcodeHeight || 15 );
          lineHeight += (barcodeSettingObject?.barcodeHeight || 15) + 3;

          const textValue = item?.sku;
          doc.text(textValue, StartingPoint, lineHeight);
          lineHeight += lineHeightIncrement;

          doc.setFontSize(barcodeSettingObject?.fontSize || 11);
          if (item?.price > 0) {
            doc.text(`${item?.price} ${orgSetting?.currency || "kr"}`,StartingPoint, lineHeight);
            lineHeight += lineHeightIncrement;
          }
          doc.setFontSize(11);

          barcodeData?.forEach((barcodeItem) => {
            if (allFields[barcodeItem]) {
              const fieldContent = allFields[barcodeItem]?.props?.children;
              const fieldText = Array.isArray(fieldContent)
                ? fieldContent.join(" ")
                : String(fieldContent);
              let line = doc.splitTextToSize(fieldText.replaceAll("\n", " "), _width - StartingPoint - 5).slice(0, 3);
              doc.text(line.join(`\n`), StartingPoint, lineHeight);
              lineHeight += line.length * 5;
            }
          });

          if (rowIndex === allBarcodeData?.length - 1) {
            doc.autoPrint();
            const pdfUrl = doc.output("bloburl");
            window.open(pdfUrl, "_blank");
          }
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
      }
    });
  };

  const handleBarcodeItem = (value) => {
    const unique_data = allBarcodeData?.find((item) => item?.id === value?.id);
    if (!unique_data) {
      const allAgencies = [...allBarcodeData, value];
      setAllBarcodeData(allAgencies);
    } else {
      const remain_agency = allBarcodeData?.filter((item) => item?.id !== value?.id);
      setAllBarcodeData(remain_agency);
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {loadItem ? (
        <CoreLoader
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <>
          <Header navigation={navigation} route={route} isHomePage isHeader />
          <ScrollView>
            <View style={styles.coreBagHeader}>
              <CoreBagCollectionHeader isHeaderImage />
            </View>
            <View style={[styles.availableProduct]}>
              <CoreText
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                }}
                textTitle={t("app.soldcollection_title", "Mina inlämmningar")}
                fontType={MontserratLight}
              />
              <View
                style={{
                  flex: 1,
                  marginTop: 10,
                  flexDirection: "row",
                  gap: 5,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  style={{
                    maxHeight: 35,
                    paddingHorizontal: 8,
                  }}
                  btnTitle={t("app.all_article", "Alla artiklar")}
                  textstyle={{ lineHeight: 10, fontSize: 12 }}
                  onPress={() => handleFilterChange("all")}
                />
                <Button
                  style={{
                    maxHeight: 35,
                    paddingHorizontal: 8,
                  }}
                  btnTitle={t("app.for_sale", "Till försäljning")}
                  textstyle={{ lineHeight: 10, fontSize: 12 }}
                  onPress={() => handleFilterChange("forSale")}
                />
                <Button
                  style={{
                    maxHeight: 35,
                    paddingHorizontal: 8,
                  }}
                  btnTitle={t("app.for_sold", "Sålda varor")}
                  textstyle={{ lineHeight: 10, fontSize: 12 }}
                  onPress={() => handleFilterChange("sold")}
                />
                <Button
                  style={{
                    maxHeight: 35,
                    paddingHorizontal: 8,
                  }}
                  btnTitle={t("app.expired_items", "Utgångna artiklar")}
                  textstyle={{ lineHeight: 10, fontSize: 12 }}
                  onPress={() => handleFilterChange("expired")}
                />
              </View>
            </View>
            {/* Below are items that are items that require action  */}
            {activeFilter === "all" && (
              <View style={[styles.availableProduct, { marginBottom: 30 }]}>
                <View
                  style={{
                    marginVertical: 20,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CoreText
                    style={[styles.clothItemText, { fontSize: 16 }]}
                    textTitle={t("app.all_article", "Alla artiklar")}
                    fontType={MontserratBold}
                  />
                  {orgSetting?.self_register_item ? (
                    <Button
                      style={{
                        maxHeight: 35,
                        borderRadius: 5,
                        paddingHorizontal: 20,
                      }}
                      btnTitle={"Print Barcodes"}
                      disabled={allBarcodeData?.length === 0}
                      disableButtondata={allBarcodeData?.length === 0}
                      onPress={handlePrint}
                      textstyle={{ lineHeight: 10, fontSize: 12 }}
                    />
                  ) : (
                    <></>
                  )}
                </View>
                {requriedAllItems?.length > 0 ? (
                  requriedAllItems?.map((item, index, array) => {
                    const checked = allBarcodeData?.find(
                      (dataItem) => dataItem.id === item.id
                    );
                    return (
                      <View
                        key={index}
                        style={[
                          styles.clothCardSection,
                          { position: "relative" },
                        ]}
                      >
                        {orgSetting?.self_register_item ? (
                          <View style={{ position: "absolute", right: 0 }}>
                            <Checkbox
                              status={checked ? "checked" : "unchecked"}
                              onPress={() => {
                                handleBarcodeItem(item);
                              }}
                              color={
                                orgDetail?.[0]?.primaryColor || Colors.BROWN
                              }
                            />
                          </View>
                        ) : (
                          <></>
                        )}

                        <View style={{ width: 100 }}>
                          <Photo
                            style={[styles.productImage, { marginRight: 20 }]}
                            source={{ uri: item?.photo?.[0]?.url || imageSrc }}
                          />
                        </View>
                        <View style={styles.imageSection}>
                          <View>
                            <CoreText
                              style={[
                                styles.productDetails,
                                { fontSize: 14, marginBottom: 0 },
                              ]}
                              textTitle={`${item.brand || ""} ${
                                item.brand && item.description ? "&" : ""
                              } ${item.description}`}
                              fontType={MontserratBold}
                            />
                          </View>
                          {(item?.gender || item?.category) && (
                            <View>
                              <CoreText
                                dropOffItem
                                FirstTitle={item?.gender}
                                lastTitle={item?.category}
                                icon={
                                  item?.gender &&
                                  item?.category && (
                                    <AntDesign
                                      name="right"
                                      size={9}
                                      color="rgba(15, 15, 15, 0.4)"
                                    />
                                  )
                                }
                                style={[
                                  styles.headerTitle,
                                  {
                                    color: "rgba(0, 0, 0, 0.48)",
                                    fontSize: 12,
                                  },
                                ]}
                                fontType={MontserratLight}
                              />
                            </View>
                          )}
                          <View
                            style={[
                              styles.consideredByStore,
                              styles.cardDescription,
                            ]}
                          >
                            <CoreText
                              style={styles.storeText}
                              textTitle={item.label}
                              fontType={MontserratBold}
                            />
                          </View>
                          <View
                            style={[
                              styles.consideredByStore,
                              styles.cardDescription,
                            ]}
                          >
                            <CoreText
                              style={styles.storeText}
                              textTitle={`Försäljningspris i butik ${
                                item.price || 0
                              } SEK`}
                              fontType={MontserratBold}
                            />
                          </View>
                          <View
                            style={[
                              styles.consideredByStore,
                              styles.cardDescription,
                            ]}
                          >
                            <CoreText
                              style={styles.storeText}
                              textTitle={`Beräknade komission vid försäljning är ${
                                (item.price * item.commission) / 100 || 0
                              } SEK`}
                              fontType={MontserratBold}
                            />
                          </View>
                          <CoreText
                            style={[styles.labelMargin, styles.storeText]}
                            textTitle={`Inlämningsdatum: ${moment(
                              item?.createdat
                            ).format("YYYY/MM/DD")}`}
                            fontType={MontserratBold}
                          />
                          <CoreText
                            style={[styles.labelMargin, styles.storeText]}
                            textTitle={`${moment().diff(
                              item?.createdat,
                              "days"
                            )} dagar sen`}
                            fontType={MontserratBold}
                          />
                          {item?.expire_day ? (
                            <View>
                              <CoreText
                                style={[styles.labelMargin, styles.storeText]}
                                textTitle={`Artikeln utgår om ${item?.expire_day} dagar`}
                                fontType={MontserratBold}
                              />
                              <ExpiredComponent
                                createdat={item?.createdat}
                                expireDay={item?.expire_day}
                              />
                            </View>
                          ) : (
                            <></>
                          )}
                          {selfServiceFlow ||
                            (orgSetting?.self_register_item && (
                              <View>
                                <CoreText
                                  style={[
                                    styles.productDetails,
                                    { fontSize: 12, marginTop: 10 },
                                  ]}
                                  textTitle={`SKU : ${
                                    item.sku ? item.sku : `3-${index + 1}`
                                  }`}
                                  fontType={MontserratBold}
                                />

                                <View>
                                  <Button
                                    style={{
                                      backgroundColor: "black",
                                      maxHeight: 33,
                                      borderRadius: 5,
                                    }}
                                    btnTitle={"Print Barcode"}
                                    onPress={() =>
                                      handleClick({
                                        ...item,
                                        sku: item.sku || `3-${index + 1}`,
                                      })
                                    }
                                    textstyle={[
                                      { lineHeight: 10, fontSize: 12 },
                                    ]}
                                  />
                                </View>
                              </View>
                            ))}
                        </View>
                        <View
                          style={{
                            justifyContent: "center",
                            width: 40,
                            alignItems: "center",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              handleActionRequired(item);
                            }}
                            style={{ flexDirection: "row" }}
                          >
                            <MaterialIcons
                              name="keyboard-arrow-right"
                              size={30}
                              color="black"
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <View style={styles.noDataCard}>
                    <Text>No Data Found</Text>
                  </View>
                )}
              </View>
            )}

            {/* Below are items that are ready to be sold */}
            {activeFilter === "forSale" && (
              <View style={[styles.availableProduct, { marginBottom: 30 }]}>
                <View style={{ marginVertical: 20 }}>
                  <CoreText
                    style={[styles.clothItemText, { fontSize: 16 }]}
                    textTitle={t("app.for_sale", "Till försäljning")}
                    fontType={MontserratBold}
                  />
                </View>
                {forSaleItems?.length > 0 ? (
                  forSaleItems?.map((item, index, array) => (
                    <View key={index} style={styles.clothCardSection}>
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ width: 100 }}>
                          <Photo
                            style={[styles.productImage, { marginRight: 20 }]}
                            source={{ uri: item?.photo?.[0]?.url || imageSrc }}
                          />
                        </View>
                        <View style={styles.imageSection}>
                          <View>
                            <CoreText
                              style={[
                                styles.productDetails,
                                { fontSize: 14, marginBottom: 0 },
                              ]}
                              textTitle={`${item.brand || ""} ${
                                item.brand && item.description ? "&" : ""
                              } ${item.description}`}
                              fontType={MontserratBold}
                            />
                          </View>
                          {(item?.gender || item?.category) && (
                            <View>
                              <CoreText
                                dropOffItem
                                FirstTitle={item?.gender}
                                lastTitle={item?.category}
                                icon={
                                  item?.gender &&
                                  item?.category && (
                                    <AntDesign
                                      name="right"
                                      size={9}
                                      color="rgba(15, 15, 15, 0.4)"
                                    />
                                  )
                                }
                                style={[
                                  styles.headerTitle,
                                  {
                                    color: "rgba(0, 0, 0, 0.48)",
                                    fontSize: 12,
                                  },
                                ]}
                                fontType={MontserratLight}
                              />
                            </View>
                          )}
                          <View
                            style={[
                              styles.consideredByStore,
                              styles.cardDescription,
                            ]}
                          >
                            <CoreText
                              style={styles.storeText}
                              textTitle={`Försäljningspris i butik ${
                                item.price || 0
                              } SEK`}
                              fontType={MontserratBold}
                            />
                          </View>
                          <View
                            style={[
                              styles.consideredByStore,
                              styles.cardDescription,
                            ]}
                          >
                            <CoreText
                              style={styles.storeText}
                              textTitle={`Beräknade komission vid försäljning är ${
                                (item.price * item.commission) / 100 || 0
                              } SEK`}
                              fontType={MontserratBold}
                            />
                          </View>
                          <CoreText
                            style={[styles.labelMargin, styles.storeText]}
                            textTitle={`Inlämningsdatum: ${moment(
                              item?.createdat
                            ).format("YYYY/MM/DD")}`}
                            fontType={MontserratBold}
                          />
                          <CoreText
                            style={[styles.labelMargin, styles.storeText]}
                            textTitle={`${moment().diff(
                              item?.createdat,
                              "days"
                            )} dagar sen`}
                            fontType={MontserratBold}
                          />
                          {item?.expire_day ? (
                            <View>
                              <CoreText
                                style={[styles.labelMargin, styles.storeText]}
                                textTitle={`Artikeln utgår om ${item?.expire_day} dagar`}
                                fontType={MontserratBold}
                              />
                              <ExpiredComponent
                                createdat={item?.createdat}
                                expireDay={item?.expire_day}
                              />
                            </View>
                          ) : (
                            <></>
                          )}
                        </View>
                      </View>
                    </View>
                  ))
                ) : (
                  <View style={styles.noDataCard}>
                    <Text>No Data Found</Text>
                  </View>
                )}
              </View>
            )}

            {/* Below are items that are sold */}
            {activeFilter === "sold" && (
              <View style={[styles.availableProduct, { marginBottom: 30 }]}>
                <View style={{ marginVertical: 20 }}>
                  <CoreText
                    style={[styles.clothItemText, { fontSize: 16 }]}
                    textTitle={t("app.for_sold", "Sålda varor")}
                    fontType={MontserratBold}
                  />
                </View>
                {soldItems?.length > 0 ? (
                  soldItems?.map((item, index, array) => (
                    <View key={index} style={styles.clothCardSection}>
                      <View style={{ width: 100 }}>
                        <Photo
                          style={[styles.productImage, { marginRight: 20 }]}
                          source={{ uri: item?.photo?.[0]?.url || imageSrc }}
                        />
                      </View>
                      <View style={styles.imageSection}>
                        <View>
                          <CoreText
                            style={[
                              styles.productDetails,
                              { fontSize: 14, marginBottom: 0 },
                            ]}
                            textTitle={`${item.brand || ""} ${
                              item.brand && item.description ? "&" : ""
                            } ${item.description}`}
                            fontType={MontserratBold}
                          />
                        </View>
                        {(item?.gender || item?.category) && (
                          <View>
                            <CoreText
                              dropOffItem
                              FirstTitle={item?.gender}
                              lastTitle={item?.category}
                              icon={
                                item?.gender &&
                                item?.category && (
                                  <AntDesign
                                    name="right"
                                    size={9}
                                    color="rgba(15, 15, 15, 0.4)"
                                  />
                                )
                              }
                              style={[
                                styles.headerTitle,
                                { color: "rgba(0, 0, 0, 0.48)", fontSize: 12 },
                              ]}
                              fontType={MontserratLight}
                            />
                          </View>
                        )}
                        <View
                          style={[
                            styles.consideredByStore,
                            styles.cardDescription,
                          ]}
                        >
                          <CoreText
                            style={styles.storeText}
                            textTitle={`Din komission är ${
                              (item.price * item.commission) / 100 || 0
                            } SEK`}
                            fontType={MontserratBold}
                          />
                        </View>
                        {item?.item_sold_date ? (
                          <CoreText
                            style={[styles.labelMargin, styles.storeText]}
                            textTitle={`Försäljningsdatum ${moment().diff(
                              item?.item_sold_date,
                              "days"
                            )} dagar sen`}
                            fontType={MontserratBold}
                          />
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  ))
                ) : (
                  <View style={styles.noDataCard}>
                    <Text>No Data Found</Text>
                  </View>
                )}
              </View>
            )}

            {/* Below are items that are expired */}
            {activeFilter === "expired" && (
              <View style={[styles.availableProduct, { marginBottom: 30 }]}>
                <View style={{ marginVertical: 20 }}>
                  <CoreText
                    style={[styles.clothItemText, { fontSize: 16 }]}
                    textTitle={t("app.expired_items", "Utgångna artiklar")}
                    fontType={MontserratBold}
                  />
                </View>
                {itemExpired?.length > 0 ? (
                  itemExpired?.map((item, index, array) => (
                    <View key={index} style={styles.clothCardSection}>
                      <View style={{ width: 100 }}>
                        <Photo
                          style={[styles.productImage, { marginRight: 20 }]}
                          source={{ uri: item?.photo?.[0]?.url || imageSrc }}
                        />
                      </View>
                      <View style={styles.imageSection}>
                        <View>
                          <CoreText
                            style={[
                              styles.productDetails,
                              { fontSize: 14, marginBottom: 0 },
                            ]}
                            textTitle={`${item.brand || ""} ${
                              item.brand && item.description ? "&" : ""
                            } ${item.description}`}
                            fontType={MontserratBold}
                          />
                        </View>
                        {(item?.gender || item?.category) && (
                          <View>
                            <CoreText
                              dropOffItem
                              FirstTitle={item?.gender}
                              lastTitle={item?.category}
                              icon={
                                item?.gender &&
                                item?.category && (
                                  <AntDesign
                                    name="right"
                                    size={9}
                                    color="rgba(15, 15, 15, 0.4)"
                                  />
                                )
                              }
                              style={[
                                styles.headerTitle,
                                { color: "rgba(0, 0, 0, 0.48)", fontSize: 12 },
                              ]}
                              fontType={MontserratLight}
                            />
                          </View>
                        )}
                        {item?.expire ? (
                          <View>
                            <CoreText
                              style={[styles.labelMargin, styles.storeText]}
                              textTitle={`Artikeln utgick ${moment(
                                item?.expire
                              ).format("YYYY/MM/DD")}`}
                              fontType={MontserratBold}
                            />
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  ))
                ) : (
                  <View style={styles.noDataCard}>
                    <Text>No Data Found</Text>
                  </View>
                )}
              </View>
            )}
            <CoreFooter />
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
};
export default SoldClothBagCollection;
